import React, {useState} from 'react'
import { 
    Route, Routes, Navigate, useLocation
} from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import Login from './Login/Login'
import Home from './Home/Home'
import Homologados from './Homologaciones/Homologados/Homologados'
import NoHomologados from './Homologaciones/NoHomologados/NoHomologados'
import AsignarNoHomologados from './Homologaciones/NoHomologados/AsignarNoHomologados'

import { Typography, Layout } from 'antd'
import { useIdleTimer } from 'react-idle-timer'
import '../Styles/Routes/Home/Home.css'
import Top from '../Components/Top/Top'
import CargarData from './CargarData/CargarData'
import Sidebar from '../Components/Sidebar/Sidebar'
import DownloadData from './DownloadData/DownloadData'
import MaestraProductos from './Administracion/MaestraProductos/MaestraProductos'
import Distribuidoras from './Administracion/Distribuidoras/Distribuidoras'
import ControlDistribuidors from './Administracion/Distribuidoras/ControlDistributors'
import ListaPrecios from './Administracion/ListaPrecios/ListaPrecios'
import Status from './Status/Status'
import UploadFile from './UploadFile/UploadFile'
import MaestraProductosGrow from './Administracion/MaestraGrow/MaestraProductosGrow'
import MaestraClientesGrow from './Administracion/MaestraGrow/MaestraClientesGrow'
import MaestraClientesSoftys from './Administracion/MaestraSoftys/MaestraClientesSoftys'
import MaestraPrices from './Administracion/MaestraPrices/MaestraPrices'
import UnitsMeasures from './Administracion/UnitsMeasures/UnitsMeasures'
import TiposUsuarios from './Administracion/Administrador/TiposUsuarios'
import EditarTipoUsuario from '../Components/Administracion/Administrador/EditarTipoUsuario'
import CrearTipoUsuario from '../Components/Administracion/Administrador/CrearTipoUsuario'
import Permisos from './Administracion/Administrador/Permisos'
import EditarPermiso from '../Components/Administracion/Administrador/EditarPermiso'
import Usuarios from './Administracion/Administrador/Usuarios'
import { InactivityLogOutReducer } from '../Redux/Actions/Login/Login'
import CrearUsuario from '../Components/Administracion/Administrador/Usuarios/CrearUsuario'
import EditarUsuario from '../Components/Administracion/Administrador/Usuarios/EditarUsuario'
import CargaArchivos from './Administracion/Administrador/CargaArchivos'
import Audits from './Audits/Audits'
import CreateUpdateUnitsMeasures from '../Components/Administracion/UnitsMeasures/CreateUpdateUnitsMeasures'
import AssignmentProductMassive from './Administracion/AssignmentProductMassive/AssignmentProductMassive'

const Index = () => {

    const { Title } = Typography
    const { Content, Footer } = Layout
    const [collapsed, setCollapsed] = useState(false)
    const dispatch = useDispatch()
    const Location = useLocation()

    const {
		rex_data_user_login
    } = useSelector(({login}) => login)

    const validateToken = () => {
        return !localStorage.getItem('usutoken') ? false : true
    }

    const onIdle = () => {
        if(Location.pathname !== "/login"){
            dispatch(InactivityLogOutReducer())
        }
    }

    const onActive = () => {
        // console.log("onActive");
    }

    let timeout = 600000
    // let timeout = 3000

    useIdleTimer({
        onIdle,
        onActive,
        timeout,
        throttle: 500
    })

    return (
        <>
            {
                validateToken()
                ?<></>
                :<Routes>
                    <Route path="*" element={<Navigate replace to="/login" />}></Route>
                    <Route exact path='/login' element={validateToken() ? <Navigate replace to="/status" /> :  <Login />}></Route>
                </Routes>
            }
            {
                validateToken() ?
                <Layout style={{flexDirection: 'column', height: '100%'}}>
                    <Top 
                        collapsed = {collapsed}
                        setCollapsed = {setCollapsed}
                        valToken = {false}
                    />
                    <Content
                        style={{
                            width: '100%',
                            display: 'flex',
                            backgroundColor: '#ffffff'
                        }}
                    >
                        <Sidebar 
                            collapsed = {collapsed}
                            setCollapsed = {setCollapsed}
                        />
                        <Routes>
                            <Route path="*" element={<Navigate replace to="/login" />} />
                            <Route exact path='/login' element={validateToken() ? <Navigate replace to="/status" /> :  <Login />}/>
                            <Route exact path='/home' element={<Home collapsed = {collapsed} />}></Route>
                            <Route exact path='/homologados' element={<Homologados collapsed = {collapsed} />}></Route>
                            <Route exact path='/asignar-no-homologados/:id?/:combo?' element={<AsignarNoHomologados collapsed = {collapsed} />}></Route>
                            <Route exact path='/asignar-homologados/:id?/:combo?' element={<AsignarNoHomologados collapsed = {collapsed} />}></Route>
                            <Route exact path='/no-homologados' element={<NoHomologados collapsed = {collapsed} />}></Route>
                            <Route exact path='/maestra-productos' element={<MaestraProductos collapsed = {collapsed} />}></Route>
                            <Route exact path='/cargar-data' element={<CargarData collapsed = {collapsed} />}></Route>
                            <Route exact path='/download-data' element={<DownloadData collapsed = {collapsed} />}></Route>
                            <Route exact path='/status/:id?' element={<Status collapsed = {collapsed} />}></Route>
                            <Route exact path='/distribuidoras' element={<Distribuidoras collapsed = {collapsed} />}></Route>
                            <Route exact path='/control-distribuidoras' element={<ControlDistribuidors collapsed = {collapsed} />}></Route>
                            <Route exact path='/lista-precios' element={<ListaPrecios collapsed = {collapsed} />}></Route>
                            <Route exact path='/upload-file' element={<UploadFile collapsed = {collapsed} />}></Route>
                            <Route exact path='/maestra-productos-grow' element={<MaestraProductosGrow collapsed = {collapsed} />}></Route>
                            <Route exact path='/maestra-clientes-grow' element={<MaestraClientesGrow collapsed = {collapsed} />}></Route>
                            <Route exact path='/maestra-clientes-softys' element={<MaestraClientesSoftys collapsed = {collapsed} />}></Route>
                            <Route exact path='/maestra-precios' element={<MaestraPrices collapsed = {collapsed} />}></Route>
                            <Route exact path='/unidades-de-medidas' element={<UnitsMeasures collapsed = {collapsed} />}></Route>
                            <Route exact path='/crear-unidades-de-medidas' element={<CreateUpdateUnitsMeasures collapsed = {collapsed} />}></Route>
                            <Route exact path='/actualizar-unidades-de-medidas' element={<CreateUpdateUnitsMeasures collapsed = {collapsed} />}></Route>
                            <Route exact path='/asignacion-de-productos-masivo' element={<AssignmentProductMassive collapsed = {collapsed} />}></Route>
                            <Route exact path='/admin/tipos-usuarios' element={<TiposUsuarios collapsed = {collapsed} />}></Route>
                            <Route exact path='/admin/editar-tipo-usuario/:id' element={<EditarTipoUsuario collapsed = {collapsed} />}></Route>
                            <Route exact path='/admin/crear-tipo-usuario' element={<CrearTipoUsuario collapsed = {collapsed} />}></Route>
                            <Route exact path='/admin/permisos' element={<Permisos collapsed = {collapsed} />}></Route>
                            <Route exact path='/admin/editar-permiso/:id' element={<EditarPermiso collapsed = {collapsed} />}></Route>
                            <Route exact path='/admin/usuarios' element={<Usuarios collapsed = {collapsed} />}></Route>
                            <Route exact path='/admin/crear-usuario' element={<CrearUsuario collapsed = {collapsed} />}></Route>
                            <Route exact path='/admin/editar-usuario/:id' element={<EditarUsuario collapsed = {collapsed} />}></Route>
                            <Route exact path='/admin/carga-archivos' element={<CargaArchivos collapsed = {collapsed} />}></Route>
                            <Route exact path='/audits' element={<Audits collapsed = {collapsed} />}></Route>
                        </Routes>
                    </Content>
                    <Footer
                        style={{
                            textAlign: 'center',
                            padding: '14px 50px',
                            backgroundColor: '#fff',
                            position: 'relative',
                            zIndex: 3
                        }}
                    >
                        <Title level={5} style={{marginBottom: 0, fontSize:'12px'}}>
                            Todos los derechos reservados a @Grow Analytics 2023
                        </Title>
                    </Footer>
                </Layout>
                :<Layout style={{flexDirection: 'column', height: '100%'}}>
                    <Top 
                        collapsed = {collapsed}
                        setCollapsed = {setCollapsed}
                        valToken = {true}
                    />
                    <Content
                        style={{
                            width: '100%',
                            display: 'flex',
                            backgroundColor: '#ffffff'
                        }}
                    >
                        <Sidebar 
                            collapsed = {collapsed}
                            setCollapsed = {setCollapsed}
                        />
                        <Routes>
                            <Route exact path='/status/:id?' element={<Status collapsed = {collapsed} />}></Route>
                        </Routes>
                    </Content>
                    <Footer
                        style={{
                            textAlign: 'center',
                            padding: '14px 50px',
                            backgroundColor: '#fff',
                            position: 'relative',
                            zIndex: 3
                        }}
                    >
                        <Title level={5} style={{marginBottom: 0, fontSize:'12px'}}>
                            Todos los derechos reservados a @Grow Analytics 2023
                        </Title>
                    </Footer>
                </Layout>
            }
        </>
    )
}

export default Index