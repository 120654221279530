import React, { useState } from 'react'
import config from '../../config'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom"
import { ActivityLogIn, FindUserAzureReducer, LoginReducer, ChangeEmailAzureReducer } from '../../Redux/Actions/Login/Login'
import { Row, Col, Typography, Form, Input, Button, Modal } from 'antd'
import {
    UserOutlined,
    LockOutlined,
} from '@ant-design/icons'
import '../../Styles/Routes/Login/Login.css'
import ImgPalmas from '../../Assets/Img/Login/palmas.jpg'
import Expired from '../../Assets/Img/Login/expired.gif'
import ImgAzureBlue from '../../Assets/Img/Login/iconAzureBlue.png'

const Login = () => {
    const { Title } = Typography
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [activeModalAzure, setActiveModalAzure] = useState(false)

    const {
		rex_session_inactivity,
        rex_value_email_azure,
    } = useSelector(({login}) => login)

    const onFinish = async (data) => {
        await dispatch(LoginReducer(data.correo, 1, data.password)) && navigate('/status')
    }

    const onAzure = async () => {
        const response = await dispatch(FindUserAzureReducer(rex_value_email_azure))
        if(response){
            setActiveModalAzure(false)
            window.location.href = `${config.api_public}/log-in-azure?email=${rex_value_email_azure}`
        }
    }

    return (
        <div
            style={{
                width: '100%',
                height: '100vh'
            }}
        >
        {
            rex_session_inactivity
            ?<Row style={{height: '100%', backgroundColor: '#24235F'}}>
                <Col span={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <img src={Expired} style={{width: '400px'}} />
                </Col>
                <Col span={12} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start'}}>
                    <p style={{fontFamily: 'Inter', fontSize: '40px', color: '#fff', marginTop: '8px'}}>¡Lo sentimos tu sesión ha expirado!</p>
                    <Button type="primary" size='large' style={{width: '200px'}} onClick={() => dispatch(ActivityLogIn())}>Inicia Session</Button>
                </Col>
            </Row>
            :<Row style={{height: '100%'}}>
                <Col 
                    span={12} 
                    className="Col-Banner-Login"
                >
                    <div className="Icon-Banner-Login"></div>
                    <Title
                        className="Title-Banner-Login"
                    >
                        Y tú ¿Cuánto <br/>vendiste ayer?
                    </Title>
                    <p 
                        className="Text-Banner-Login"
                    >
                        Analiza lo mejor. Todo es realidad con nosotros
                    </p>
                </Col>
                <Col 
                    span={12} 
                    className="Col-Form-Login"
                >
                    <div
                        className="Container-Title-Login"
                    >
                        <img src={ImgPalmas} style={{margin: '0 auto 5px auto'}} />
                        <Title 
                            className="Title-Form-Login"
                            level={2}
                        >
                            Inicia Sesión
                        </Title>
                    </div>
                    <Form
                        onFinish={onFinish}
                        className="Form-Container-Item-Login"
                        layout="vertical"
                        autoComplete="off"
                    >
                        <Form.Item
                            name="correo"
                            label="Usuario"
                            className="Item-Form-Login"
                        >
                            <Input 
                                className="Input-Form-Login"
                                prefix={<UserOutlined />}
                                placeholder="Escribe tu usuario" 
                            />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            label="Contraseña"
                            className="Item-Form-Login"
                        >
                            <Input.Password 
                                className="Input-Form-Login"
                                prefix={<LockOutlined />}
                                placeholder="Escribe tu contraseña" 
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                className="Button-Form-Login"
                                htmlType="submit"
                            >
                                Ingresar
                            </Button>
                        </Form.Item>
                    </Form>
                    <Button
                        className="Button-Azure-Login"
                        onClick={() => setActiveModalAzure(true)}
                    >
                        Iniciar con Azure <img src={ImgAzureBlue} width={28} style={{marginLeft: '10px'}} />
                    </Button>
                    <Modal 
                        title="Ingresa tu correo de azure" 
                        className="Modal-Email-Azure"
                        closeIcon={false}
                        open={activeModalAzure} 
                        okText="Inicia con Azure"
                        cancelText="Cerrar"
                        onOk={() => onAzure()} 
                        onCancel={() => setActiveModalAzure(false)}
                    >
                        <Input 
                            onChange={(e) => dispatch(ChangeEmailAzureReducer(e.target.value))} 
                            placeholder="Correo" 
                            value={rex_value_email_azure} 
                        />
                    </Modal>
                </Col>
            </Row>
        }
        </div>
    )
}

export default Login