// const api_url = 'http://127.0.0.1:8006/'
// const api_url = 'http://192.168.100.15:8006/'
const api_url = 'https://pepm-hml-back.grow-solutions.com/'
export default {
    api_url : api_url,
    api_public: api_url+'public/',
    api: api_url+'protected/',
    basename: '/', 
    produccion : false,
};
