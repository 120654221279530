import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { 
    ObtenerMaestraClientesGrowReducer 
} from '../../../Redux/Actions/Administracion/MaestraGrow/MaestraGrow'
import { Typography, Table, Card, Input, Button, Pagination, Skeleton } from 'antd'
import {
    FilterOutlined,
    ReloadOutlined
} from '@ant-design/icons'

const MaestraClientesGrow = (props) => {

    const collapsed = props.collapsed

    const { Title } = Typography
    const dispatch = useDispatch()
    const [ pageTable, setPageTable ] = useState(1)

    const getColumnSearchProps = (dataIndex, nameColumn) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{ padding: 8 }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Filtrar ${nameColumn}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({ closeDropdown: false })
                    }}
                    style={{ display: 'block' }}
                />
            </div>
        ),
        filterIcon: () => (<FilterOutlined />),
        onFilter: (value, record) => {
            if(dataIndex == 'created_at'){
                let fecha = value.split('/')
                let dia = fecha[0]
                let mes = fecha[1]
                let anio = fecha[2]
                let fecha_comparar = dia ? dia : ''
                fecha_comparar = mes ? mes + '-' + fecha_comparar : fecha_comparar
                fecha_comparar = anio ? anio + '-' + fecha_comparar : fecha_comparar
                return record[dataIndex].toString().toLowerCase().includes(fecha_comparar.toLowerCase())
            }else{
                const text = record[dataIndex] || ''
                return text.toString().toLowerCase().includes(value.toLowerCase())
            }
        }
    })
    
    const columns = [
        {
            title: 'Código Organización',
            dataIndex: 'cod_ship_to',
            ...getColumnSearchProps('cod_ship_to','código organización'),
            sorter: (a, b) => a.cod_ship_to ? a.cod_ship_to.localeCompare(b.cod_ship_to) : false,
            showSorterTooltip: false,
            render: (cod_ship_to) => <div title={cod_ship_to}>{cod_ship_to}</div>,
            width: '120px',
        },
        {
            title: 'Organización Ventas',
            dataIndex: 'ship_to',
            ...getColumnSearchProps('ship_to','organización ventas'),
            sorter: (a, b) => a.ship_to ? a.ship_to.localeCompare(b.ship_to) :false,
            showSorterTooltip: false,
            render: (ship_to) => <div title={ship_to}>{ship_to}</div>,
            width: '120px'
        },
        {
            title: 'Código Destinatario',
            dataIndex: 'cod_sold_to',
            ...getColumnSearchProps('cod_sold_to','código destinatario'),
            sorter: (a, b) => b.cod_sold_to?  a.cod_sold_to.localeCompare(b.cod_sold_to) : false,
            showSorterTooltip: false,
            render: (cod_sold_to) => <div title={cod_sold_to}>{cod_sold_to}</div>,
            width: '130px'
        },
        {
            title: 'Destinatario',
            dataIndex: 'sold_to',
            ...getColumnSearchProps('sold_to', 'destinatario'),
            sorter: (a, b) => a.sold_to ? a.sold_to.localeCompare(b.sold_to) : false,
            showSorterTooltip: false,
            render: (sold_to) => <div title={sold_to}>{sold_to}</div>,
            width: '130px'
        },
        {
            title: 'Cliente Hml',
            dataIndex: 'cliente_hml',
            ...getColumnSearchProps('cliente_hml','cliente hml'),
            sorter: (a, b) => a.cliente_hml ? a.cliente_hml.localeCompare(b.cliente_hml) : false,
            showSorterTooltip: false,
            render: (cliente_hml) => <div title={cliente_hml}>{cliente_hml}</div>,
            width: '130px'
        },
        {
            title: 'Cliente Sub Hml',
            dataIndex: 'client_sub_hml',
            ...getColumnSearchProps('client_sub_hml', 'cliente sub hml'),
            sorter: (a, b) => a.client_sub_hml ? a.client_sub_hml.localeCompare(b.client_sub_hml) : false,
            showSorterTooltip: false,
            render: (client_sub_hml) => <div title={client_sub_hml}>{client_sub_hml}</div>,
            width: '130px'
        },
        {
            title: 'Locality',
            dataIndex: 'locality',
            ...getColumnSearchProps('locality', 'locality'),
            sorter: (a, b) => a.locality ? a.locality.localeCompare(b.locality) : false,
            showSorterTooltip: false,
            render: (locality) => <div title={locality}>{locality}</div>,
            width: '130px'
        },
        {
            title: 'Cod Executor',
            dataIndex: 'cod_executor',
            ...getColumnSearchProps('cod_executor','cod executor'),
            sorter: (a, b) => a.cod_executor ? a.cod_executor.localeCompare(b.cod_executor) : false,
            showSorterTooltip: false,
            render: (cod_executor) => <div title={cod_executor}>{cod_executor}</div>,
            width: '130px'
        },
        {
            title: 'Executor',
            dataIndex: 'executor',
            ...getColumnSearchProps('executor','executor'),
            sorter: (a, b) => a.executor ? a.executor.localeCompare(b.executor) : false,
            showSorterTooltip: false,
            render: (executor) => <div title={executor}>{executor}</div>,
            width: '130px'
        },
        {
            title: 'Zone Management',
            dataIndex: 'zone_management',
            ...getColumnSearchProps('zone_management','zone management'),
            sorter: (a, b) => a.zone_management ? a.zone_management.localeCompare(b.zone_management) : false,
            showSorterTooltip: false,
            render: (zone_management) => <div title={zone_management}>{zone_management}</div>,
            width: '130px'
        },
        {
            title: 'Zone',
            dataIndex: 'zone',
            ...getColumnSearchProps('zone','zone'),
            sorter: (a, b) => a.zone ? a.zone.localeCompare(b.zone) : false,
            showSorterTooltip: false,
            render: (zone) => <div title={zone}>{zone}</div>,
            width: '130px'
        },
        {
            title: 'Channel',
            dataIndex: 'channel',
            ...getColumnSearchProps('channel', 'channel'),
            sorter: (a, b) => a.channel ? a.channel.localeCompare(b.channel) : false,
            showSorterTooltip: false,
            render: (channel) => <div title={channel}>{channel}</div>,
            width: '130px'
        },
        {
            title: 'Regional Management',
            dataIndex: 'regional_management',
            ...getColumnSearchProps('regional_management','regional management'),
            sorter: (a, b) => a.regional_management ? a.regional_management.localeCompare(b.regional_management) : false,
            showSorterTooltip: false,
            render: (regional_management) => <div title={regional_management}>{regional_management}</div>,
            width: '130px'
        },
        {
            title: 'Regional GBA',
            dataIndex: 'regional_gba',
            ...getColumnSearchProps('regional_gba', 'regional gba'),
            sorter: (a, b) => a.regional_gba ? a.regional_gba.localeCompare(b.regional_gba) : false,
            showSorterTooltip: false,
            render: (regional_gba) => <div title={regional_gba}>{regional_gba}</div>,
            width: '130px'
        },
        {
            title: 'Customer Group',
            dataIndex: 'customer_group',
            ...getColumnSearchProps('customer_group','customer group'),
            sorter: (a, b) => a.customer_group ? a.customer_group.localeCompare(b.customer_group) : false,
            showSorterTooltip: false,
            render: (customer_group) => <div title={customer_group}>{customer_group}</div>,
            width: '130px'
        },
        {
            title: 'CG2',
            dataIndex: 'cg2',
            ...getColumnSearchProps('cg2','cg2'),
            sorter: (a, b) => a.cg2 ? a.cg2.localeCompare(b.cg2) : false,
            showSorterTooltip: false,
            render: (cg2) => <div title={cg2}>{cg2}</div>,
            width: '130px'
        },
        {
            title: 'Attr 1',
            dataIndex: 'attr_1',
            ...getColumnSearchProps('attr_1','attr 1'),
            sorter: (a, b) => a.attr_1 ? a.attr_1.localeCompare(b.attr_1) : false,
            showSorterTooltip: false,
            render: (attr_1) => <div title={attr_1}>{attr_1}</div>,
            width: '130px'
        },
        {
            title: 'Attr 2',
            dataIndex: 'attr_2',
            ...getColumnSearchProps('attr_2','attr 2'),
            sorter: (a, b) => a.attr_2 ? a.attr_2.localeCompare(b.attr_2) : false,
            showSorterTooltip: false,
            render: (attr_2) => <div title={attr_2}>{attr_2}</div>,
            width: '130px'
        },
        {
            title: 'Attr 3',
            dataIndex: 'attr_3',
            ...getColumnSearchProps('attr_3','attr 3'),
            sorter: (a, b) => a.attr_3 ? a.attr_3.localeCompare(b.attr_3) : false,
            showSorterTooltip: false,
            render: (attr_3) => <div title={attr_3}>{attr_3}</div>,
            width: '130px'
        },
        {
            title: 'Attr 4',
            dataIndex: 'attr_4',
            ...getColumnSearchProps('attr_4','attr 4'),
            sorter: (a, b) => a.attr_4 ? a.attr_4.localeCompare(b.attr_4) : false,
            showSorterTooltip: false,
            render: (attr_4) => <div title={attr_4}>{attr_4}</div>,
            width: '130px'
        },
        {
            title: 'Attr 5',
            dataIndex: 'attr_5',
            ...getColumnSearchProps('attr_5','attr 5'),
            sorter: (a, b) => a.attr_5 ? a.attr_5.localeCompare(b.attr_5) : false,
            showSorterTooltip: false,
            render: (attr_5) => <div title={attr_5}>{attr_5}</div>,
            width: '130px'
        },
        {
            title: 'Attr 6',
            dataIndex: 'attr_6',
            ...getColumnSearchProps('attr_6', 'attr 6'),
            sorter: (a, b) => a.attr_6 ? a.attr_6.localeCompare(b.attr_6) : false,
            showSorterTooltip: false,
            render: (attr_6) => <div title={attr_6}>{attr_6}</div>,
            width: '130px'
        },
        {
            title: 'Fec. Actualización',
            dataIndex: 'created_at',
            ...getColumnSearchProps('created_at', 'fecha actualización'),
            sorter: (a, b) => {
                const creacionA = a.created_at || ''
                const creacionB = b.created_at || ''
                return creacionA.localeCompare(creacionB)
            },
            showSorterTooltip: false,
            render: (created_at) => <div title={created_at?created_at:''}>{created_at? moment(created_at).format('DD/MM/YYYY') : ''}</div>,
            width: '120px'
        }
    ]

    const {
        rex_data_maestra_clientes_grow,
        rex_cargando_maestra_clientes_grow,
        rex_feha_actualizacion_maestra_clientes_grow
    } = useSelector(({maestraGrow}) => maestraGrow)
    
    const ObtenerClientesGrow = async () => {
        await dispatch(ObtenerMaestraClientesGrowReducer())
    }

    useEffect(() => {
        if(rex_data_maestra_clientes_grow.length == 0){
            ObtenerClientesGrow()
        }
    }, [])

    return (
        
        <div 
            style={{
                display: 'flex',
                width: !collapsed ? 'calc(100% - 180px)' : 'calc(100% - 80px)',
                transition: 'width .7s'
            }}
        >
            <Card className="Card-Container">
                <Card 
                    title={
                        <Title className="Title-Module">
                            Maestra de Clientes Grow {rex_feha_actualizacion_maestra_clientes_grow?.espfechaactualizacion
                                ? <span style={{fontSize: '12px'}}>{moment(rex_feha_actualizacion_maestra_clientes_grow.espfechaactualizacion).format('DD/MM/YYYY hh:mm:a')}</span>
                                : <span style={{fontSize: '12px'}}>(20/06/2023 17:20 PM)</span>
                            }
                        </Title>
                    }
                    className="Card-Item"
                >
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px'}}>
                        <Button
                            style={{display:'flex', alignItems:'center', marginRight:'10px'}} size='small'
                            onClick={()=> {
                                ObtenerClientesGrow()
                            }}
                        >
                            <ReloadOutlined
                                style={{fontSize:'14px', marginRight: '4px',color:'#1677ff',}}
                            />Recargar
                        </Button>
                        { rex_cargando_maestra_clientes_grow && rex_data_maestra_clientes_grow.length == 0
                        ? <Skeleton.Input active={true} size='default'/>
                        : <div style={{display:'flex', columnGap: '10px', alignItems: 'center'}}>
                            <Pagination
                                current={pageTable}
                                total={rex_data_maestra_clientes_grow.length}
                                pageSize={15}
                                onChange={(page) => setPageTable(page)}
                                size='small'
                            />
                            <div>Total: {Intl.NumberFormat('en-IN').format( rex_data_maestra_clientes_grow.length)} registros</div>
                        </div>
                        }
                        
                    </div>
                    { rex_cargando_maestra_clientes_grow && rex_data_maestra_clientes_grow.length == 0
                    ? <div style={{marginTop:'10px'}}>
                            <Skeleton/>
                            <Skeleton/>
                    </div>
                    : <Table 
                            pagination={{
                                position: ["none", "none"],
                                pageSize: 15,
                                current: pageTable
                            }}
                            scroll={{
                                x: '1500px',
                                y: '450px',
                            }}
                            columns={columns}
                            dataSource={rex_data_maestra_clientes_grow} 
                            className="Table-Home"
                            size='small'
                        />
                    }
                </Card>
            </Card>
        </div>
    )
}

export default MaestraClientesGrow