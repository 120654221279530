import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import es_ES from "antd/lib/locale/es_ES"
import { Typography, Card, Table, Row, Col, DatePicker, ConfigProvider, Skeleton, Button } from 'antd'
import { TokenUserReducer } from '../../Redux/Actions/Status/Status'
import IconoCalendario from '../../Assets/Img/Comunes/calendario.png'
import IconoRevenue from '../../Assets/Img/Status/iconoRevenue.png'
import IconoSac from '../../Assets/Img/Status/iconoSac.png'
import '../../Styles/Routes/Status/Status.css'
import ModalDetailStatus from '../../Components/Modal/ModalDetailStatus'
import {
    ArrowLeftOutlined
} from '@ant-design/icons'
import { ObtenerEstadosPendientesReducer } from '../../Redux/Actions/Status/Status'
import moment from 'moment'
import dayjs from 'dayjs'

const Status = (props) => {
    const collapsed = props.collapsed

    const { Title } = Typography
    const dispatch = useDispatch()
    const { id } = useParams()
    const navigate = useNavigate()

    const [ openModalStatus, setOpenModalStatus ] = useState(false)
    const [ dateFilter, setDateFilter ] = useState(dayjs().subtract(1, 'month'))

    const columns = [
        {
            title: 'Áreas',
            dataIndex: 'area',
            render: (_, record) => {
                return <div style={{display:'flex', justifyContent:'initial', alignItems:'center'}}>
                            <img style={{width:'30x', height:'30px', marginRight:'10px'}} src={record.arenombre == 'Revenue' ? IconoRevenue : IconoSac}/>
                            <span className='Cell-Text-Blue-Table-Status'>
                                {
                                    record.arenombre == "Revenue"
                                    ? 'Trade/IC'
                                    : record.arenombre == "SAC"
                                        ? "SAC+Trade/IC"
                                        : record.arenombre
                                }
                            </span>
                        </div>
            },
            align : 'center'
        },
        {
            title: 'Base de datos',
            dataIndex: 'base_datos',
            render: (_, record) => {
                return <div className='Cell-Table-Status'>
                        {
                            record.esps.map(bd => (
                            <div className='Cell-Text-Gray-Table-Status-Initial'>{bd.espbasedato}</div>
                            ))
                        }
                        {/* {
                            record.arenombre == 'Ventas'
                            ?
                            <div style={{display:'flex', justifyContent:'center', flexDirection:'column', textAlign:'initial'}}>
                                <span
                                    style={{fontSize:'10px', color: '#1ec0ed', cursor:'pointer'}} 
                                    onClick={()=> setOpenModalStatus(!openModalStatus)}
                                >Click Detalle</span>
                            </div>
                            :null
                        } */}
                    </div>
            },
            align : 'center'
        },
        {
            title: 'Responsable',
            dataIndex: 'responsable',
            render: (_, record) => {
                return <div className='Cell-Table-Status-Ventas'>
                        {
                            record.esps.map(res => (
                                <div 
                                    className='Cell-Text-Table-Status Color-Text-Gray-Status'
                                >
                                    {
                                        res.espresponsable == "SAC"
                                        ? "SAC+Trade/IC"
                                        : res.espresponsable
                                    }
                                </div>
                                ))
                        }
                        {record.arenombre == 'Ventas'
                        ? <div className='Cell-Text-Table-Status Color-Text-Gray-Status'>&nbsp;</div>
                        :null}
                        </div>
            },
            align : 'center'
        },
        {
            title: 'Usuario',
            dataIndex: 'usuario',
            render: (_, record) => {
                return <div className='Cell-Table-Status'>
                        {
                            record.esps.map(usu => (
                                <div className='Cell-Text-Table-Status Color-Text-Gray-Status'>{usu.perpersonas? usu.perpersonas.pernombrecompleto : '-'}</div>
                            ))
                        }
                        {record.arenombre == 'Ventas'
                        ? <div className='Cell-Text-Table-Status Color-Text-Gray-Status'>&nbsp;</div>
                        :null}
                        </div>
            },
            align : 'center'
        },
        {
            title: 'Deadline',
            dataIndex: 'deadline',
            render: (_, record) => {
                return <div className='Cell-Table-Status'>
                    {
                        record.esps.map(del => (
                            <div className='Cell-Text-Table-Status Color-Text-Gray-Status'>{moment(del.espfechaprogramado).utc().format('DD/MM/YYYY')}</div>
                        ))
                    }
                    {record.arenombre == 'Ventas'
                    ? <div className='Cell-Text-Table-Status Color-Text-Gray-Status'>&nbsp;</div>
                    :null}
                    </div>
            },
            align : 'center'
        },
        {
            title: 'Deadline Actualizado',
            dataIndex: 'deadline_actualizado',
            render: (_, record) => {
                return <div className='Cell-Table-Status'>
                    {
                        record.esps.map(dela => (
                        <div className='Cell-Text-Table-Status Color-Text-Gray-Status'>
                            {dela.espfechactualizacion != null
                            ? moment(dela.espfechactualizacion).utc().format('DD/MM/YYYY')
                            : '-'}
                        </div>))
                    }
                    {record.arenombre == 'Ventas'
                    ? <div className='Cell-Text-Table-Status Color-Text-Gray-Status'>&nbsp;</div>
                    :null}
                    </div>
            },
            width:'100px',
            align : 'center'
        },
        {
            title: 'Dias de retraso',
            dataIndex: 'dia_retraso',
            render: (_, record) => {
                return <div className='Cell-Table-Status'>
                    { record.esps.map(dia => (
                            <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                                <span
                                    className={`${parseInt(dia.espdiaretraso) > 0 ? 'Cell-Text-Red-Table-Status' : 'Cell-Text-Green-Table-Status'}`}
                                >{dia.espdiaretraso} días</span>
                                <div className={`Box-Day-Late-Status ${parseInt(dia.espdiaretraso) > 0 ? 'Color-Red-Status' : 'Color-Green-Status'}`}></div>
                            </div>
                        ))
                    }
                    {record.arenombre == 'Ventas'
                        ? <div className='Cell-Text-Table-Status Color-Text-Gray-Status'>&nbsp;</div>
                        : null
                    }
                </div>
            },
            width:'80px',
            align : 'center'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width:'90px',
            render: (_, record) => {
                return <div className='Cell-Table-Status'>
                        {
                            record.arenombre == 'SAC'
                            ? 
                                <span className={`Cell-Text-Table-Status ${record.areporcentaje == '100' ? 'Color-Text-Green-Status' : 'Color-Text-Red-Status'}`}>
                                {`${record.areporcentaje == '100' ? 'Cargado':'Pendiente'}`}
                                </span>
                            : record.esps.map( sta => (
                                <span className={`Cell-Text-Table-Status ${sta.espfechactualizacion != null ? 'Color-Text-Green-Status' : 'Color-Text-Red-Status'}`}>
                                {`${sta.espfechactualizacion != null ? 'Cargado':'Pendiente'}`}
                                </span>
                            ))
                            
                        }
                        {
                        record.arenombre == 'Ventas'
                            ? <div className='Cell-Text-Table-Status Color-Text-Gray-Status'>&nbsp;</div>
                            :null
                        }
                    </div>
            },
            align : 'center'
        },
    ]
    
    const ObtenerDataStatus = async (value = dayjs().subtract(1, 'month')) => {
        await dispatch(ObtenerEstadosPendientesReducer(value))
    }
    
    useEffect(() => {
        ObtenerDataStatus()
    }, [])

    const {
        data_estados_pendientes_home,
        data_estados_pendientes_distribuidoras_home,
        loading_pending_status
    } = useSelector(({status}) => status)

    useEffect(() => {
      const fetchToken = async () => {
        if(!localStorage.getItem('usutoken') || id){
            if(id){
                let tokenVal = await dispatch(TokenUserReducer(id, true))
                if(tokenVal){
                    navigate('/status')
                }else{
                    navigate('/login')
                }
            }else{
                navigate('/login')
            }
        }
      }

      fetchToken()
    }, [])

    return (
        <div 
            style={{
                display: 'flex',
                width: !collapsed ? 'calc(100% - 180px)' : 'calc(100% - 80px)',
                transition: 'width .7s'
            }}
        >
            <Card className="Card-Container">
                <Card 
                    title={
                        <div style={{display:'flex', alignItems:'center'}}>
                            <Button 
                                type='primary' 
                                size='small' 
                                ghost 
                                onClick={() => navigate(-1)} 
                                className="Button-Title-Module"
                            >
                                <ArrowLeftOutlined />
                            </Button>
                            <Title className="Title-Module">Status</Title>
                        </div>
                    }
                    className="Card-Item"
                >
                    <Row className='Container-Filter-Date-Status-Top'>
                        <img src={IconoCalendario} style={{marginRight:'5px', width:'20px', height: '20px'}} />
                        <span className='Text-Filter-Date-Status-Top'>Fecha</span>
                        <ConfigProvider locale={es_ES}>
                            <DatePicker
                                size='small'
                                placeholder='Fecha'
                                className='Date-Picker-Status-Top'
                                picker="month"
                                inputReadOnly={true}
                                onChange={async (value) => {
                                    await dispatch(ObtenerEstadosPendientesReducer(value))
                                    setDateFilter(dayjs(value))
                                }}
                                defaultValue={dateFilter} 
                                format={['YYYY-MM']}
                            />
                        </ConfigProvider>
                    </Row>

                    {
                        loading_pending_status
                        ? <div style={{display:'flex', justifyContent:'space-between'}}>
                            <Skeleton.Input active={true} size='large' />
                            <Skeleton.Input active={true} size='large' />
                            <Skeleton.Input active={true} size='large' />
                            {/* <Skeleton.Input active={true} size='large' /> */}
                        </div>
                        : <Row style={{justifyContent:'space-between'}}>
                            <Col className='Container-Metric-Status-Top'>
                                <div className='Container-Text-Metric-Status-Top'>
                                    <img src={IconoRevenue}/>
                                    <span>Trade/IC</span>
                                </div>
                                <div>
                                    <span className='Text-Percentage-Status-Top'>
                                        {
                                            data_estados_pendientes_home.length > 0
                                            ? data_estados_pendientes_home[0]['areporcentaje']+'%'
                                            : '0%'
                                        }
                                    </span>
                                </div>
                            </Col>
                            <Col className='Container-Metric-Status-Top'>
                                <div className='Container-Text-Metric-Status-Top'>
                                    <img src={IconoSac} />
                                    <span>Ventas</span>
                                </div>
                                <div>
                                    <span className='Text-Percentage-Status-Top'>
                                        {
                                            data_estados_pendientes_home.length > 0
                                            ? data_estados_pendientes_home[1]['areporcentaje']+'%'
                                            :'0%'
                                        }
                                    </span>
                                </div>
                            </Col>
                            <Col className='Container-Metric-Status-Top'>
                                <div className='Container-Text-Metric-Status-Top'>
                                    <img src={IconoSac} />
                                    <span>SAC+Trade/IC</span>
                                </div>
                                <div>
                                    <span className='Text-Percentage-Status-Top'>
                                        {   
                                            data_estados_pendientes_home.length > 0
                                            ? data_estados_pendientes_home[2]['areporcentaje'] == 0 
                                                ? <div className='Text-Alert-Need-Approve'>
                                                    Falta<br/>Homologar
                                                </div>
                                                : '0%'
                                            : '0%'
                                        }
                                    </span>
                                </div>
                            </Col>
                            {/* <Col className='Container-Metric-Status-Top'>
                                <div className='Container-Text-Metric-Status-Top'>
                                    <img src={IconoSac} />
                                    <span>DTS <span className='Text-Description-Percentage-Status-Top'>(Sell Out)</span></span>
                                </div>
                                <div>
                                    <span className='Text-Percentage-Status-Top'>
                                        {data_estados_pendientes_distribuidoras_home.filter(dts => dts.espfechactualizacion != null).length}DT
                                    </span>
                                </div>
                            </Col> */}
                        </Row>
                    }                    
                    {
                        loading_pending_status
                        ? <div style={{margin:'10px 0'}}>
                            <Skeleton active={true}/>
                            <Skeleton active={true} />
                        </div>
                        : <Table
                            columns={columns}
                            dataSource={data_estados_pendientes_home.filter(are => are.arenombre!= 'DT')}
                            className="Table-Status"
                            size='small'
                            loading={loading_pending_status}
                            rowClassName={(record, index) => {
                                if(record.arenombre == 'SAC'){
                                    return 'Row-Sac-Table-Status'
                                }
                                if(record.arenombre == 'Ventas'){
                                    return 'Row-Ventas-Table-Status'
                                }
                            }}
                        />
                    }
                    
                </Card>
            </Card>
            <ModalDetailStatus
              modalOpen={openModalStatus}
              setModalOpen={setOpenModalStatus}
            />
        </div>
    )
}

export default Status